import "./App.css";
import HomePage from "./Components/HomePage/HomePage";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import JoinWaitlist from "./Components/JoinWaitlist/JoinWaitlist";
import { EmailProvider } from "./Components/Context/Context";
import ProtectedRoute from "./Components/Guard/ProtectedRoute";

function App() {
  return (
    <>
      <Router>
        <EmailProvider>
          <Routes>
            <Route
              exact
              path="/"
              element={
                <>
                  {" "}
                  <HomePage />{" "}
                </>
              }
            />{" "}
            <Route
              exact
              path="/join"
              element={
                <>
                  {" "}
                  <ProtectedRoute>
                    <JoinWaitlist />{" "}
                  </ProtectedRoute>{" "}
                </>
              }
            />{" "}
          </Routes>{" "}
        </EmailProvider>{" "}
      </Router>{" "}
    </>
  );
}

export default App;
