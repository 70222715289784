import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import flex2RideLogo from "../../images/flex2ride-logo.svg";
import mainCar from "../../images/main-car.png";
import { message } from "antd";
import { useEmail } from "../Context/Context";
import "./HomePage.css";
import CustomForm from "./CustomForm";
import MailchimpFormContainer from "./MailchimpFormContainer";
const HomePage = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const url =
    "https://flex2ride.us22.list-manage.com/subscribe/post?u=6de55529bf0066f47c1530e3c&amp;id=888d0fd74c";
  const { setEmail } = useEmail();
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState("");

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleNavigate = () => {
    setEmail(inputValue);
    if (!validateEmail(inputValue)) {
      messageApi.open({
        type: "warning",
        content: "Valid Email Required",
      });
    } else {
      navigate("/join");
    }
  };
  return (
    <div className="homepage-container">
      <div className="homepage-details">
        <div className="logo">
          <img src={flex2RideLogo} alt="Flex 2 Ride Logo" />
        </div>
        <div className="text-container">
          <h4>
            The Ultimate All-in-One Super App for Ride Hailing, Logistics, Food
            Ordering, and Bill Payment.
          </h4>
          <p>
            Experience the luxury and convenience of Flex2ride, where premium
            service meets innovative technology. Say goodbye to the hassle of
            switching between apps and hello to the future of daily living.
          </p>
        </div>
        <div className="input-container">
          {contextHolder}
          <MailchimpFormContainer />
        </div>
        <div className="flex2ride-image">
          <img src={mainCar} alt="Flex 2 Ride Car" />
        </div>
      </div>
    </div>
  );
};

export default HomePage;
