import React, { useState } from "react";
import { useEmail } from "../Context/Context";
import { Spin } from "antd";
import { useNavigate } from "react-router-dom";
const CustomForm = ({ status, message, onValidated }) => {
  const { setEmail } = useEmail();
  const [inputEmail, setInputEmail] = useState("");
  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    // Make sure to check if `onValidated` is passed correctly
    if (onValidated) {
      inputEmail && onValidated({ EMAIL: inputEmail }); // Call the function
      setEmail(inputEmail);
      if (status === "success") {
        console.log(status);
      } else if (status === "error") {
        console.log("Failed to subscribe, please enter a valid email.");
        // messageApi.open({
        //   type: "warning",
        //   content: "Failed to subscribe, please enter a valid email.",
        // });
      }
    } else {
      console.error("onValidated is not a function");
    }
  };
  return (
    <div>
      <div className="input-container">
        <form onSubmit={(e) => handleSubmit(e)}>
          {/* {status === "sending" && (
            <div className="sending-text">Joining...</div>
          )} */}
          {status === "error" && (
            <div
              className="error-text"
              dangerouslySetInnerHTML={{ __html: message }}
            />
          )}
          {status === "success" && navigate("/join")}

          <input
            type="email"
            placeholder="Email Address"
            required
            value={inputEmail}
            onChange={(e) => setInputEmail(e.target.value)}
          />
          <button type="submit">
            {status === "sending" ? (
              <Spin className="loading-state" />
            ) : (
              "Get Early Access"
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default CustomForm;
