import React from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import CustomForm from "./CustomForm";
const MailchimpFormContainer = (props) => {
  const url =
    "https://flex2ride.us22.list-manage.com/subscribe/post?u=6de55529bf0066f47c1530e3c&amp;id=888d0fd74c";
  return (
    <div>
      <MailchimpSubscribe
        url={url}
        render={({ subscribe, status, message }) => (
          <CustomForm
            status={status}
            message={message}
            onValidated={(formData) => subscribe(formData)}
          />
        )}
      />
    </div>
  );
};

export default MailchimpFormContainer;
