import React from "react";
import { useNavigate } from "react-router-dom";
import "./JoinWaitlist.css";
import mainCar from "../../images/main-car.png";
import { useEmail } from "../Context/Context";
import { AiFillCloseCircle } from "react-icons/ai";
import joinWaitListEmoji from "../../images/join-waitlist-emoji.svg";
const JoinWaitlist = () => {
  const { email } = useEmail();
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/");
  };
  return (
    <div className="join-wait-list-container">
      <AiFillCloseCircle
        className="join-wait-icon-cancel"
        onClick={handleNavigate}
      />
      <div className="join-wait-list-details">
        <div className="join-wait-list-emoji">
          <img src={joinWaitListEmoji} alt="Wait List Emoji" />
        </div>
        <div className="wait-list-text">
          <h4>We've added you to our waitlist!</h4>
          <p>We'll let you know when we launch</p>
        </div>
        <div className="wait-list-text-container">
          <input type="text" value={email} readOnly />
        </div>
        <div className="flex2ride-image">
          <img src={mainCar} alt="Flex 2 Ride Car" />
        </div>
      </div>
    </div>
  );
};

export default JoinWaitlist;
