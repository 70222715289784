import React from "react";
import { Navigate } from "react-router-dom";
import { useEmail } from "../Context/Context"; // Adjust the path to your context

const ProtectedRoute = ({ children }) => {
  const { email } = useEmail();
  if (!email) {
    return <Navigate to="/" />;
  }
  return children;
};

export default ProtectedRoute;
